import { Container, Row, Col } from 'react-bootstrap';
import '../index.css';

const videoData = [
    {
        id: 'HeUSYE6lWgg',
        title: "Merry Go Round of Life - Howl's Moving Castle",
        subtitle: 'Joe Hisaishi',
    },
    {
        id: 'F8dzAEK4pfE',
        title: 'Harry Potter Soundtrack',
        subtitle: 'Petr Potter Machane',
    },
    {
        id: 'G2YqugCBu9I',
        title: 'Tetris Main Theme',
        subtitle: 'Music Based on a Russian Folk Song arr. Tyler Hatfield',
    },
    {
        id: '5xbLs0WE-8k',
        title: '3 Duos Concertantes for 2 Oboes, Op. 13',
        subtitle: 'Franz Wilhelm Ferling',
    },
    {
        id: '6QBeuJLG8eE',
        title: 'Oboe Sonata in D Major Ad libitum',
        subtitle: 'Camille Saint-Saëns',
    },
    {
        id: 'rcIJXck-NOo',
        title: 'Scheherazade mvt. 2, Oboe Solo',
        subtitle: 'N. Rimsky-Korsakov',
    },
];

const ProjectCard = ({ id, title, subtitle }) => (
    <Col className="mb-4">
        <iframe
            className="video"
            title={title}
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://youtube.com/embed/${id}?autoplay=0`}
        ></iframe>
        <h1 className="pt-2 fs-2 text-center fw-light">{title}</h1>
        <h5 className="mb-4 text-center text-gray">{subtitle}</h5>
    </Col>
);

const ProjectsAbout = () => {
    return (
        <Container className="d-flex flex-column justify-content-between my-5">
            <Row className="mb-4">
                <h1
                    className="text-aqua fw-semibold mb-0 heading-shadow"
                >
                    My Projects
                </h1>
                <span className="text-aqua fw-light fs-5">
                    Here are a few examples of my latest and most exciting projects.
                </span>
            </Row>
            <Row xs={1} md={2}>
                {videoData.map((video, index) => (
                    <ProjectCard key={index} id={video.id} title={video.title} subtitle={video.subtitle} />
                ))}
            </Row>
        </Container>
    );
};

export default ProjectsAbout;
