import { Image } from 'react-bootstrap';
import logo from './Icons/logo.svg';

const Loading = () => {
    return(
        <div style={{width:'100vw', height:'100vh'}} className='d-flex flex-column align-items-center justify-content-center'>
            <Image src={logo} style={{width:'23rem'}} className='mb-3'></Image>
            <h2 className='fs-5'>loading...</h2>
        </div>
    )
}

export default Loading;