import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdReviews } from "react-icons/md";
import { IoOptionsSharp } from "react-icons/io5";
import Img from './Images/elo.png';
import Checkmark from './Icons/checkmark.png';
import ReviewCard from './ReviewCard';
import '../index.css';
import { useEffect } from 'react';

const ProductOne = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, [])
    
    return (
        <>
            <Container className="d-flex flex-column justify-content-between mt-5">
                <div className='h-100 w-100 grid-container'>
                    <div className='item item1 p-4'>
                        <Image src={Img} className='image-grid ' />
                    </div>

                    <div className='item item2 p-4'>
                        <h1 className='fs-4 fw-light mb-4' style={{color: '#4e6e77'}}>Other Options <IoOptionsSharp style={{color: '#4e6e77'}} /></h1>
                        <Row xs={1} md={2} className="">
                            <Col>
                                <Link to="/shop/product2" className="text-reset text-decoration-none">
                                    <div className="bg-transparent border rounded-2 h-100 p-2 d-flex flex-column justify-content-between align-items-center shadow-sm hover-effect" style={{ cursor: 'pointer' }}>
                                        <h1 className="mt-1 fw-medium fs-4 text-center" style={{color: '#303131'}}>Recording Up To 5 min</h1>
                                        <h5 className="my-1 fw-lighter fs-5 text-gray">~$200</h5>
                                    </div>
                                </Link>
                            </Col>
                            <Col>
                                <Link to="/shop/product3" className="text-reset text-decoration-none">
                                    <div className="bg-transparent border rounded-2 p-2 d-flex flex-column align-items-center shadow-sm hover-effect" style={{ cursor: 'pointer' }}>
                                        <h1 className="mt-1 fw-medium fs-4 text-center" style={{color: '#303131'}}>Recording Up To 10 min</h1>
                                        <h1 className="my-1 fw-lighter fs-5 text-gray">~$500</h1>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                        <hr className='my-5'></hr>
                        <div className=''>
                            <h1 className='fs-4 fw-light mb-4' style={{color: '#4e6e77'}}>Customer Reviews <MdReviews style={{color: '#4e6e77'}} /></h1>
                            <ReviewCard stars={5} body="I had an oboe part that was relatively simple but needed to fit into the tapestry of a larger work. Julia played it precisely as instructed, and it fit into the piece very well. Excellent seller with great value for money." nickname="burgerhr" />
                            <ReviewCard stars={5} body="Wonderful cooperation. I needed to record oboe part into my music composition project. Great musician, beautiful playing." nickname="petrmachane" />
                            <ReviewCard stars={5} body="Julia delivered an outstanding oboe performance for my orchestral arrangement. Her attention to detail and flawless execution brought the entire piece to life. Truly a talented musician, and a pleasure to work with!" nickname="samuelmusic" />
                        </div>
                    </div>

                    <div className='p-4 item item3'>
                        <h1 className='fw-semibold'>Recording Up to 1 Minute</h1>
                        <h4 className='fw-lighter text-gray'>Oboe Record</h4>
                        <h1 className='fw-bold' style={{ fontSize: '3rem' }}>~$80</h1>
                        <Link to='/contact' className='w-100 text-reset text-decoration-none'>
                            <Button
                                className='bg-blue border text-black fs-4 fw-semibold shadow mb-5 contact-button'
                                size="lg"
                                style={{ border: '3px solid #9CDFF7' }}
                            >
                                Get Your Custom Recording Now
                            </Button>
                        </Link>

                        <div className='w-100 pt-0'>
                            <hr className='m-0 my-4'></hr>
                            <p className='my-0' style={{ color: '#303131', fontSize: '1.1rem' }}>
                                Looking to add a beautiful oboe to your tracks? I offer a professional oboe recording service that guarantees high-quality audio, expert mixing, and fast delivery, perfect for solos, accompaniment, or specific sections of your music.
                            </p>
                            <hr className='m-0 my-4'></hr>
                            <p className='mb-2' style={{ color: '#303131', fontSize: '1.1rem' }}>
                                <b>What My Service Includes:</b>
                            </p>
                            <ul style={{ listStyleImage: `url(${Checkmark})`, color: '#303131' }} className='mb-4'>
                                <li className='mb-2'><b>High-Quality Audio File:</b> Receive a crisp, clear oboe recording in your choice of .wav or .mp3 format.</li>
                                <li className='mb-2'><b>Mixing & Mastering:</b> I’ll ensure the oboe blends seamlessly with your track for a polished final product.</li>
                                <li className='mb-2'><b>1 Track:</b> Ideal for those needing an oboe part for a single piece of music.</li>
                                <li className='mb-2'><b>48-Hour Delivery:</b> Quick turnaround to keep you on schedule.</li>
                                <li className='mb-2'><b>1 Free Revision:</b> If adjustments are needed, I provide one revision at no extra charge.</li>
                                <li className='mb-2'><b>Commercial Use:</b> The recording is fully licensed for commercial use.</li>
                                <li className='mb-2'><b>Optional Video:</b> Upon request, receive a video of the oboe performance.</li>
                            </ul>

                            <p className='mb-2' style={{ color: '#303131', fontSize: '1.1rem' }}>
                                <b>What I Need from You:</b>
                            </p>
                            <ul className='mb-4' style={{ color: '#303131' }}>
                                <li className='mb-2'><b>Track/Backing Track:</b> Please send your track in .wav or .mp3 format for synchronization.</li>
                                <li className='mb-2'><b>Preferences:</b> Share your preferred style, mood, or specific sound you’re aiming for.</li>
                                <li className='mb-2'><b>Sheet Music (if available):</b> Provide it in PDF format. If you don’t have it, I can compose it for you.</li>
                                <li className='mb-2'><b>BPM (Beats Per Minute):</b> Indicate the BPM for precise rhythmic alignment.</li>
                            </ul>

                            <p className='mb-2' style={{ color: '#303131', fontSize: '1.1rem' }}>
                                <b>What You’ll Receive:</b>
                            </p>
                            <ul className='mb-4' style={{ color: '#303131' }}>
                                <li className='mb-2'><b>Audio File:</b> A high-quality oboe solo in .wav or .mp3 format.</li>
                                <li className='mb-2'><b>Mixed Track:</b> A version of the oboe recording mixed with your provided track.</li>
                                <li className='mb-2'><b>(Optional) Performance Video:</b> Available upon request.</li>
                            </ul>

                            <p className='mb-4' style={{ color: '#303131' }}>
                                <b>Need Oboe for Just a Part of Your Song?</b> If you only need the oboe for a specific section, I offer shorter recording packages. Feel free to contact me for tailored options to fit your project.
                            </p>
                            <p className='mb-4' style={{ color: '#303131' }}>
                                <b>Pricing:</b> My prices vary based on the complexity of the song and the time required for recording. However, they are typically close to the rates listed. Please reach out if you have any questions regarding pricing for your project.
                            </p>
                            <p className='mb-4' style={{ color: '#303131' }}>
                                <b>How to Order:</b> Contact me to discuss your project needs, style preferences, and any special requests. Together, we’ll ensure the oboe recording matches your vision perfectly.
                            </p>
                            <p className='mb-0' style={{ color: '#303131' }}>
                                <b>Sample Request:</b> Want to hear a sample before committing? I offer a $15-second sample of my work for $10, so you can be confident it’s the perfect fit for your project.
                            </p>
                            <hr className='m-0 my-4'></hr>
                            <p className='my-0' style={{ color: '#303131', fontSize: '1.1rem' }}>
                                I’m dedicated to delivering top-quality oboe recordings and look forward to elevating your music with the unique sound of the oboe. <b>Contact me today to get started!</b>
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
            <br />
        </>
    );
};

export default ProductOne;
