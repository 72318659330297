import React from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import img from "./Images/about.png";

const About = () => {
    return (
        <>
            <Container className="d-flex flex-column justify-content-between my-5">
                <Row xs={1} lg={2}>
                    <Col>
                        <Image
                            src={img}
                            className="w-100"
                            style={{ boxShadow: '20px 20px #506D78' }}
                            alt="About Us"
                        />
                    </Col>
                    <Col className="d-flex flex-column justify-content-center align-items-start align-items-lg-end pt-5 py-lg-5">
                        <h1
                            className="text-aqua fw-semibold mb-3 text-end"
                            style={{ fontSize: '3.5rem', textShadow: '2px 2px 4px rgba(0,0,0,0.25)' }}
                        >
                            About Me
                        </h1>
                        <p className="fw-light fs-4 d-none d-xl-block text-start text-lg-end ps-lg-5">
                            I'm Julia, an oboist with a lifelong passion for music.
                            I've been playing the oboe since childhood, and over the years, it has become my way of expressing emotion and creativity.
                            I now offer professional oboe recordings for various projects, delivering high-quality sound tailored to your needs.
                            Whether it's for film, commercials, or other media, I'm here to help bring your vision to life with the beautiful tone of the oboe.
                        </p>
                        <p className="fw-light fs-5 d-block d-xl-none text-start text-lg-end ps-lg-5">
                            I'm Julia, an oboist with a lifelong passion for music.
                            I've been playing the oboe since childhood, and over the years, it has become my way of expressing emotion and creativity.
                            I now offer professional oboe recordings for various projects, delivering high-quality sound tailored to your needs.
                            Whether it's for film, commercials, or other media, I'm here to help bring your vision to life with the beautiful tone of the oboe.
                        </p>
                    </Col>
                </Row>
            </Container>
            <br />
        </>
    );
}

export default About;
