import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Components/Navbar.js';
import Footer from "./Components/Footer.js";
import Hero from "./Components/Hero.js";
import Cards from './Components/Cards.js';
import Products from "./Components/ProductsHome.js";
import Projects from "./Components/Projects.js";
import Shop from "./Components/Shop.js";
import Contact from "./Components/Contact.js";
import About from "./Components/About.js";
import ProjectsAbout from "./Components/ProjectsAbout.js";
import Product1 from "./Components/Product1.js";
import Product2 from "./Components/Product2.js";
import Product3 from "./Components/Product3.js";
import Loading from "./Components/Loading.js";
import Reviews from "./Components/Reviews.js";

const Layout = ({ children }) => (
  <>
    <Navbar />
    {children}
    <Footer />
  </>
);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("load", setLoading(false));
  }, []);

  return (
    <div>
      {loading ? (
        <Loading /> 
      ) : (
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Hero />
                  <Cards />
                  <Projects />
                  <Products />
                  <Reviews />
                </Layout>
              }
            />
            <Route
              path="/about"
              element={
                <Layout>
                  <About />
                  <ProjectsAbout />
                </Layout>
              }
            />
            <Route
              path="/shop"
              element={
                <Layout>
                  <Shop />
                </Layout>
              }
            />
            <Route
              path="/contact"
              element={
                <Layout>
                  <Contact />
                </Layout>
              }
            />
            <Route
              path="/shop/product1"
              element={
                <Layout>
                  <Product1 />
                </Layout>
              }
            />
            <Route
              path="/shop/product2"
              element={
                <Layout>
                  <Product2 />
                </Layout>
              }
            />
            <Route
              path="/shop/product3"
              element={
                <Layout>
                  <Product3 />
                </Layout>
              }
            />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
