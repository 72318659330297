import ReviewCard from './ReviewCard';
import { Container, Row, Col } from 'react-bootstrap';

const Reviews = () => {
    return (
      <>
        <Container className="d-flex flex-column justify-content-center my-5">
          <Row className="mb-4">
            <h1
              className="text-aqua fw-semibold mb-0"
              style={{ fontSize: '3.5rem', textShadow: '2px 2px 4px rgba(0,0,0,0.25)' }}
            >
              Customer Reviews
            </h1>
            <span className="text-aqua fw-light fs-5">
              What My Customers Are Saying.
            </span>
          </Row>
          <Row md={3} xs={1}>
            <Col>
              <ReviewCard stars={5} body="I had an oboe part that was relatively simple but needed to fit into the tapestry of a larger work. 
              Julia played it precisely as instructed, and it fit into the piece very well. Excellent seller with great value for money." nickname="burgerhr" />
            </Col>
            <Col>
              <ReviewCard stars={5} body="Wonderful cooperation. I needed to record oboe part into my music composition project. 
              Great musician, beautiful playing." nickname="petrmachane" />   
            </Col>
            <Col>
              <ReviewCard stars={5} body="Julia delivered an outstanding oboe performance for my orchestral arrangement. 
              Her attention to detail and flawless execution brought the entire piece to life. Truly a talented musician, and a pleasure to work with!" nickname="samuelmusic" />
            </Col>
          </Row>
        </Container>
        <br></br>
        </>
    );
};

  export default Reviews;