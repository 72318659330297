import { Container, Button, Col } from 'react-bootstrap';
import mov from './Images/howls.mp4';
import img from './Images/mobile2.png';
import '../index.css';
import { Link } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { IoPause, IoPlay, IoVolumeHigh, IoVolumeMute } from "react-icons/io5";

const Hero = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handlePauseToggle = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  return (
    <>
      <Container className="mb-0 mt-3 h-100" style={{ height: '100vh' }}>
        <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-end position-relative">
          <Col
            md={12}
            lg={7}
            xl={6}
            style={{ position: 'relative', zIndex: 1 }}
            className="d-flex flex-column justify-content-center align-items-center align-items-lg-end column-custom p-1 p-md-4"
          >
            <h1 className="text-center text-lg-end w-100 fw-bolder custom-heading mb-3">
              Remote Oboe Recordings
            </h1>
            <h5 className="w-100 text-center text-lg-end fs-5 fw-lighter text-light mb-3 mb-md-5">
              Get <b className="fw-bold">high-quality</b> remote oboe recordings with <b className="fw-bold">fast delivery</b>! 
              Custom performances with professional <b className="fw-bold">mixing and mastering</b>, perfect for film scores, albums, and more.
            </h5>
            <Link
              to="/contact"
              className="w-100 d-flex justify-content-center justify-content-lg-end text-reset text-decoration-none"
            >
              <Button className="fs-4 mb-4 bg-blue fw-semibold shadow-lg contact-button-main text-black">
                Get Your Custom Recording
              </Button>
            </Link>
          </Col>
          <img className="image-main w-100" src={img}
  
          ></img>
          <video
            className="video-main w-100"
            ref={videoRef}
            autoPlay
            playsinline
            muted={isMuted}
            loop
            style={{ position: 'absolute', filter: 'brightness(50%)', height: '100%', width: '100%', objectFit: 'cover' }}
          >
            <source src={mov} type="video/mp4" />
          </video>
          <div className="p-4 d-flex" style={{ position: 'absolute', bottom: '0', left: '0', zIndex: 1 }}>
            <Button onClick={handlePauseToggle} className="me-2 bg-transparent border-blue d-none d-sm-block" size="lg">
              {isPaused ? <IoPlay className="h-100" /> : <IoPause />}
            </Button>
            <Button onClick={handleMuteToggle} className="me-2 bg-transparent border-blue d-none d-sm-block" size="lg">
              {isMuted ? <IoVolumeMute /> : <IoVolumeHigh />}
            </Button>
          </div>
        </div>
      </Container>
      <br />
    </>
  );
};

export default Hero;
