const ReviewCard = ({ stars, body, nickname }) => {
    const renderStars = (stars) => {
      const starElements = [];
      for (let i = 0; i < 5; i++) {
        starElements.push(
          <span key={i} style={{ color: i < stars ? '#FFD700' : '#ccc' }}>
            ★
          </span>
        );
      }
      return starElements;
    };
  
    return (
      <div style={styles.card}>
        <div style={styles.stars}>{renderStars(stars)}</div>
        <p style={styles.body}>{body}</p>
        <p style={styles.nickname}>- {nickname}</p>
      </div>
    );
  };

  const styles = {
    card: {
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '16px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      fontStyle: '',
      background: '#ffffff',
      marginBottom:'1rem'
    },
    stars: {
      marginBottom: '8px',
      fontSize: '18px',
    },
    body: {
      fontSize: '15px',
      marginBottom: '10px',
      color: '#333',
    },
    nickname: {
      fontSize: '13px',
      color: '#777',
    },
  };
  
  export default ReviewCard;
