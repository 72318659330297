import React from 'react';
import { Container, Nav, Navbar, Image } from 'react-bootstrap';
import logo from './Icons/logo.svg';
import '../index.css';

function NavB() {
  return (
    <>
      <Navbar expand="lg">
        <Container className="d-flex align-items-end">
          <Navbar.Brand href="/">
            <Image src={logo} className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="d-block d-lg-flex flex-lg-row justify-content-end w-100 my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link href="/" className="pb-lg-0 px-lg-4 fs-5">Home</Nav.Link>
              <Nav.Link href="/about" className="pb-lg-0 px-lg-4 fs-5">About</Nav.Link>
              <Nav.Link href="/shop" className="pb-lg-0 px-lg-4 fs-5">Products</Nav.Link>
              <Nav.Link href="/contact" className="pb-lg-0 px-lg-4 fs-5">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavB;