import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import { GrSend } from "react-icons/gr";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';
import '../index.css';

const Contact = () => {
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const submitHandler = async (e) => {
        e.preventDefault();
        if (!name || !email || !message) {
            return toast.error('Please fill in your name, email, and message.');
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            return toast.error('Please enter a valid email address.');
        }
        if (message.length < 10) {
            return toast.error('Message should be at least 10 characters long.');
        }

        try {
            setLoading(true);
            await fetch('../../backend/mail.php', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name, email, subject, message})
            }).then(res => res.json()).then(res => {
                if (res.success) {
                    setName('');
                    setEmail('');
                    setSubject('');
                    setMessage('');
                    setLoading(false);
                    toast.success(res.success);
                } else if (res.error) {
                    setLoading(false);
                    toast.error(res.error);
                    console.log(res)
                }
            });
        } catch (err) {
            console.log(err)
        }
    };

    return (
        <>
            <Container className="d-flex flex-column justify-content-center align-items-center my-5">
                <Row className='mb-4 d-flex justify-content-center w-75'>
                    <h1
                        className="text-aqua fw-semibold mb-0 text-center text-nowrap"
                        style={{ fontSize: '3.5rem', textShadow: '2px 2px 4px rgba(0,0,0,0.25)' }}
                    >
                        Contact Me
                    </h1>
                    <span className="text-aqua fw-light fs-5 text-center">
                        If you have questions or an idea for a custom project, please contact me.
                    </span>
                </Row>
                <Row className='row-width'>
                    <ToastContainer position='top-center' limit={10} />
                    <Form onSubmit={submitHandler}>
                        <Row className="mb-1" xs={1} sm={2}>
                            <Form.Group as={Col} controlId="name">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    className='shadow-sm mb-3'
                                    onChange={(e) => setName(e.target.value)}
                                    aria-required="true"
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="email">
                                <Form.Label>Your Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    className='shadow-sm'
                                    onChange={(e) => setEmail(e.target.value)}
                                    aria-required="true"
                                />
                            </Form.Group>
                        </Row>

                        <Form.Group className="mb-3" controlId="subject">
                            <Form.Label>Select the Product You Want to Discuss</Form.Label>
                            <Form.Select className="mb-2" onChange={(e) => setSubject(e.target.value)}>
                                <option value="">-</option>
                                <option value="1">Record Up To 1 min</option>
                                <option value="2">Record Up To 5 min</option>
                                <option value="3">Record Up To 10 min</option>
                                <option value="4">Other</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="message">
                            <Form.Label>Your Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Message"
                                className='shadow-sm'
                                onChange={(e) => setMessage(e.target.value)}
                                aria-required="true"
                            />
                        </Form.Group>

                        <Button
                            disabled={loading}
                            variant="primary"
                            type="submit"
                            className='bg-brown shadow-sm border-0'
                        >
                            {loading ? 'Sending...' : 'Send Message'} <GrSend />
                        </Button>
                    </Form>
                </Row>
            </Container>
            <br />
        </>
    );
};

export default Contact;