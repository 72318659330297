import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Img from './Images/exmaple.png'; 
import '../index.css';

const Shop = () => {
  return (
    <>
      <Container className="d-flex flex-column justify-content-center my-5">
        <Row className='mb-4'>
          <h1
            className="text-aqua fw-semibold mb-0"
            style={{ fontSize: '3.5rem', textShadow: '2px 2px 4px rgba(0,0,0,0.25)' }}
          >
            Our Products
          </h1>
          <span className="text-aqua fw-light fs-5">
            Choose The Most Suitable For Your Project
          </span>
        </Row>
        <Row xs={1} md={3} className="g-4">
          <Col className="py-2">
            <Link to="/shop/product1" className='text-reset text-decoration-none'>
              <div className='bg-light border rounded-2 h-100 w-100 p-2 d-flex flex-column justify-content-between align-items-center shadow-sm'>
                <Image src={Img} className='w-100 mb-2' />
                <h1 className='mt-2 fw-semibold fs-3 text-center'>
                  Recording Up To 1 min
                </h1>
                <h5 className='fw-lighter text-gray'>
                  Oboe Record
                </h5>
                <h1 className='fw-semibold'>
                  ~$80
                </h1>
                <Link to='/contact' className='w-100 d-flex justify-content-center text-reset text-decoration-none'>
                  <Button className='bg-blue border text-black fs-6 fw-semibold shadow-sm contact-button' size="sm">
                    Get Your Custom Recording Now
                  </Button>
                </Link>
              </div>
            </Link>
          </Col>
          <Col className="py-2">
            <Link to="/shop/product2" className='text-reset text-decoration-none'>
              <div className='bg-light border rounded-2 h-100 w-100 p-2 d-flex flex-column justify-content-between align-items-center shadow-sm'>
                <Image src={Img} className='w-100 mb-2' />
                <h1 className='mt-2 fw-semibold fs-3 text-center'>
                  Recording Up To 5 min
                </h1>
                <h5 className='fw-lighter text-gray'>
                  Oboe Record
                </h5>
                <h1 className='fw-semibold'>
                  ~$200
                </h1>
                <Link to='/contact' className='w-100 d-flex justify-content-center text-reset text-decoration-none'>
                  <Button className='bg-blue border text-black fs-6 fw-semibold shadow-sm contact-button' size="sm">
                    Get Your Custom Recording Now
                  </Button>
                </Link>
              </div>
            </Link>
          </Col>
          <Col className="py-2">
            <Link to="/shop/product3" className='text-reset text-decoration-none'>
              <div className='bg-light border rounded-2 h-100 w-100 p-2 d-flex flex-column justify-content-between align-items-center shadow-sm'>
                <Image src={Img} className='w-100 mb-2' />
                <h1 className='mt-2 fw-semibold fs-3 text-center'>
                  Recording Up To 10 min
                </h1>
                <h5 className='fw-lighter text-gray'>
                  Oboe Record
                </h5>
                <h1 className='fw-semibold'>
                  ~$500
                </h1>
                <Link to='/contact' className='w-100 d-flex justify-content-center text-reset text-decoration-none'>
                  <Button className='bg-blue border text-black fs-6 fw-semibold shadow-sm contact-button' size="sm">
                    Get Your Custom Recording Now
                  </Button>
                </Link>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
      <br />
    </>
  );
}

export default Shop;
