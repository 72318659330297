import { Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';

const Projects = () => {
  const [youtubeID] = useState('F8dzAEK4pfE');
  const [youtubeID2] = useState('HeUSYE6lWgg');

  return (
    <>
      <Container className="d-flex flex-column justify-content-between my-5">
        <Row className="mb-4">
          <h1
            className="text-aqua fw-semibold mb-0"
            style={{ fontSize: '3.5rem', textShadow: '2px 2px 4px rgba(0,0,0,0.25)' }}
          >
            My Projects
          </h1>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <span className="text-aqua fw-light fs-5">
              Here are a few examples of my latest and most exciting projects.
            </span>
            <p className="m-0">
              <a
                href="/about"
                className="bg-transparent border-0 p-0 m-0 text-dark fs-5 fw-light link-offset-1"
              >
                Find Out More
              </a>
            </p>
          </div>
        </Row>
        <Row xs={1} md={2}>
          <Col>
            <iframe
              className="video"
              title="Youtube player"
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={`https://youtube.com/embed/${youtubeID2}?autoplay=0`}
            ></iframe>
            <h1 className="pt-2 fs-2 text-center fw-light">Merry Go Round of Life - Howl's Moving Castle</h1>
            <h5 className="mb-4 text-center text-gray">Joe Hisaishi</h5>
          </Col>
          <Col>
            <iframe
              className="video"
              title="Youtube player"
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}
            ></iframe>
            <h1 className="pt-2 fs-2 text-center fw-light">Harry Potter soundtrack</h1>
            <h5 className="mb-4 text-center text-gray">Petr Potter Machane</h5>
          </Col>
        </Row>
      </Container>
      <br />
    </>
  );
};

export default Projects;
