import { useState } from 'react';
import { Container, Image, Card, Row, Col } from 'react-bootstrap';
import filesvg from './Icons/file.png';
import financesvg from './Icons/finance.png';
import mailsvg from './Icons/mail.png';
import mixsvg from './Icons/mix.png';

const Cards = () => {
  const [card] = useState([
    {
      icon: <Image src={filesvg} style={{ width: '4rem' }} />,
      title: 'HQ Audio File',
      body: 'I provide you with high-quality audio files in .wav, .mp3, or .ogg formats.',
    },
    {
      icon: <Image src={mailsvg} style={{ width: '4rem' }} />,
      title: '48-Hour Delivery',
      body: 'Your order will be processed and delivered within 48 hours.',
    },
    {
      icon: <Image src={mixsvg} style={{ width: '5rem' }} />,
      title: 'Mixing & Mastering',
      body: 'Get customized mixing and mastering services to enhance your unique audio files.',
    },
    {
      icon: <Image src={financesvg} style={{ width: '4rem' }} />,
      title: 'Commercial Use',
      body: 'My recordings can be used for commercial projects.',
    },
  ]);

  return (
    <>
      <Container>
        <Row xs={1} md={2} className="g-4 my-5">
          {card.map((element, idx) => (
            <Col key={idx}>
              <Card bg="transparent" className="border-0">
                <Row>
                  <Col sm={2} className="d-flex align-items-center justify-content-center">
                    {element.icon}
                  </Col>
                  <Col sm={10}>
                    <Card.Body className='px-0'>
                      <Card.Title className="fs-3">{element.title}</Card.Title>
                      <Card.Text className="fs-5 fw-light lh-1">{element.body}</Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <br />
    </>
  );
};

export default Cards;