import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Img from './Images/exmaple.png';
import { Link } from 'react-router-dom';
import '../index.css';

const Products = () => {
  return (
    <>
      <Container className="d-flex flex-column justify-content-center my-5">
        <Row className="mb-4">
          <h1
            className="text-aqua fw-semibold mb-0"
            style={{ fontSize: '3.5rem', textShadow: '2px 2px 4px rgba(0,0,0,0.25)' }}
          >
            Featured Products
          </h1>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <span className="text-aqua fw-light fs-5">
              Most Frequently Selected Products by My Customers.
            </span>
            <p className="m-0">
              <a
                href="/shop"
                className="bg-transparent border-0 p-0 m-0 text-dark fs-5 fw-light link-offset-1"
              >
                Shop More
              </a>
            </p>
          </div>
        </Row>
        <Row xs={1} md={2} className="g-4">
          <Col className="py-2">
            <Link to="/shop/product1" className="text-reset text-decoration-none">
              <div
                className="bg-light border rounded-2 h-100 p-2 d-flex flex-column justify-content-between align-items-center shadow-sm"
                style={{ cursor: 'pointer' }}
              >
                <Image src={Img} className="w-100" alt="Record Up To 1 min" />
                <h1 className="mt-2 fw-semibold fs-2 text-center">Recording Up To 1 min</h1>
                <h5 className="fw-lighter text-gray">Oboe Record</h5>
                <h1 className="fw-semibold">~$80</h1>
                <div className="w-100 d-flex justify-content-center">
                  <Link to="/contact" className="w-50">
                    <Button
                      className="w-100 bg-blue border text-black fs-6 fw-semibold shadow-sm contact-button"
                      size="sm"
                    >
                      Get Your Custom Recording Now
                    </Button>
                  </Link>
                </div>
              </div>
            </Link>
          </Col>
          <Col className="d-flex justify-content-md-end py-2">
            <Link to="/shop/product2" className="text-reset text-decoration-none">
              <div
                className="bg-light border rounded-2 h-100 p-2 d-flex flex-column justify-content-between align-items-center shadow-sm"
                style={{ cursor: 'pointer' }}
              >
                <Image src={Img} className="w-100" alt="Record Up To 10 min" />
                <h1 className="mt-2 fw-semibold fs-2 text-center">Recording Up To 5 min</h1>
                <h5 className="fw-lighter text-gray">Oboe Record</h5>
                <h1 className="fw-semibold">~$200</h1>
                <div className="w-100 d-flex justify-content-center">
                  <Link to="/contact" className="w-50">
                    <Button
                      className="w-100 bg-blue border text-black fs-6 fw-semibold shadow-sm contact-button "
                      size="sm"
                    >
                      Get Your Custom Recording Now
                    </Button>
                  </Link>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
      <br />
    </>
  );
};

export default Products;
